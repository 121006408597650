import React from "react"
import Layout from "./index"
import messages from "../data/messages/nl"

if (!Intl.PluralRules) {
  require("@formatjs/intl-pluralrules/polyfill")
  require("@formatjs/intl-pluralrules/dist/locale-data/nl") // Add locale data for nl
}

if (!Intl.RelativeTimeFormat) {
  require("@formatjs/intl-relativetimeformat/polyfill")
  require("@formatjs/intl-relativetimeformat/dist/locale-data/nl") // Add locale data for nl
}

export default props => <Layout {...props} i18nMessages={messages} />
