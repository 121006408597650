module.exports = {
  selectLanguage: "Selecteer je taal",
  displayName: "Nederlands",
  categories: "Categorieën",
  read: "Lezen",
  price: "Prijs",
  lastUpdate: "Laatste update",
  free: "Nu gratis beschikbaar",
  Software: "Software",
  "Machine Learning": "Machine leren",
  Python: "Python",
  Azure: "Azure",
  "ARM Template": "ARM Template",
  Tags: "Tags",
  Home: "Startpagina",
  "API Management": "API Management",
  "Key Vault": "Key Vault",
  "Contact Form": "Contact Formulier",
  "Cancel": "annuleren",
  "Submit": "Verzenden",
  "Contact Message": "We stellen het op prijs als u ons feedback geeft op onze website.",
  "Flutter": "Flutter"
}
